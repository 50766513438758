<template>
  <div>
    <header class="product-header">
      <div class="container">
        <h2>كشافات الواجهة</h2>
        <!-- <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio
          numquam recusandae maxime, blanditiis, ipsa aut quibusdam voluptatibus
          obcaecati itaque dolore hic eligendi ex praesentium fugit voluptatem.
          Delectus necessitatibus sapiente consequatur!
        </p> -->
        <div class="inside-index">
          <a href="#Master" class="btn">Master</a>
          <a href="#Kenzy" class="btn">Kenzy</a>
          <a href="#Spider" class="btn">Spider</a>
        </div>
      </div>
    </header>

    <div id="Master" class="container" dir="ltr">
      <h2 class="text-center">
        <img
          src="@/assets/images/marks/marks-03.png"
          class="product-title-img"
          alt="master"
        />
      </h2>
      <div class="row">
        <ProductCard
          v-for="product in master"
          :key="product.id"
          :productData="product"
        />
      </div>
    </div>
    <div id="Kenzy" class="container" dir="ltr">
      <h2 class="text-center">
        <img
          src="@/assets/images/marks/marks-01.png"
          class="product-title-img"
          alt="kenzy"
        />
      </h2>
      <div class="row">
        <ProductCard
          v-for="product in kenzy"
          :key="product.id"
          :productData="product"
        />
      </div>
    </div>
    <div id="Spider" class="container" dir="ltr">
      <h2 class="display-2 py-2 text-center">
        <u>Spider</u>
        <!-- <img
          src="@/assets/images/marks/marks-01.png"
          class="product-title-img"
          alt="kenzy"
        /> -->
      </h2>
      <div class="row">
        <ProductCard
          v-for="product in spider"
          :key="product.id"
          :productData="product"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard";
export default {
  components: {
    ProductCard
  },
  data() {
    return {
      pData: this.$store.getters.getData
    };
  },
  computed: {
    master() {
      return this.pData.filter(a => a.family == "Master" && a.type == "i");
    },
    kenzy() {
      return this.pData.filter(a => a.family == "Kenzy" && a.type == "i");
    },
    spider() {
      return this.pData.filter(a => a.family == "Spider" && a.type == "i");
    }
  }
};
</script>
